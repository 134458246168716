import React from 'react';
import '../Footer/Footer.css';
import Form from '../Footer/Form';
import { FaArrowRight } from 'react-icons/fa';

const Footer = (props) => {
  const { bgColor, text, subText, pageId, showForm} = props;

  const footerStyle = { 
    backgroundColor: bgColor,
  };
  return (
    <div className='footer' style={footerStyle}>
      <div className='footer-text1 font-face-m'>{text}</div>
      <div className='footer-text2 font-face-r'>
      {pageId === 'contact' ?
      (<a href={ 'mailto:kcsimbu@onfleek.in'}>
          <div className='px-2 hover-container'>
            {subText}
            <FaArrowRight className='arrow-icon mx-1' />
          </div>
      </a>):(<a href={ pageId }>
          <div className='px-2 hover-container'>
            {subText}
            <FaArrowRight className='arrow-icon mx-1' />
          </div>
      </a>)}
      
      </div>
      {showForm && <Form />}
    </div>
  );
};

export default Footer;