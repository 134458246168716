import React from 'react';
import '../WhatWeDo/WhatWeDo.css';

const WhatWeDo = (props) => {
    console.log('props:', props);
    const {
        lefttext1,
        lefttext2,
        rightData, // Assuming rightData contains an array of objects
     } = props;
     console.log("rightData:",rightData)
    return (
        <section className='learnsteplayout bg-light d-flex w-100'>
            <div className='learnsteplayout-left d-flex'>
                <span className='learnsteplayout-lefttext1 font-face-m'>{lefttext1}</span>
                <span className='learnsteplayout-lefttext2 font-face-r w-75'>{lefttext2}</span>
            </div>
            <div className='learnsteplayout-right'>
                {rightData && rightData.map((data, index) => (
                    <div className='learnsteplayout-rightlayout1' key={index}>
                        <img className='wedoimg' src={data.imgSrc} alt={`icon${index + 1}`} />
                        <div className='learnsteplayout-rightlayout2'>
                            <span className='learnsteplayout-rightlayout2text1 text-dark font-face-m'>{data.heading}</span>
                            <span className='learnsteplayout-rightlayout2text2 text-dark font-face-r'>{data.text}</span>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export { WhatWeDo };
