import AbsoluteImages1 from '../../Assets/AbsoluteImages1.png';
import AbsoluteImages2 from '../../Assets/AbsoluteImages2.png';
import AbsoluteImages3 from '../../Assets/AbsoluteImages3.png';
import BackEnd from '../../Attachments/BackEndDevelopment-CourseDetails.pdf'
import FrontEnd from '../../Attachments/FrontEndDevelopment-CourseDetails.pdf';
import FullStack from '../../Attachments/FullStackDevelopment-CourseDetails.pdf';
const AbsoluteImagesData = [
    {
      img: AbsoluteImages1,
      text1: 'Full Stack Developer course',
      text2: 'Become a master in both frontend and backend development using the latest technologies. Design interfaces, build robust applications and enhance user experience and functionality.',
      moreDetails: FullStack
    },
    {
      img: AbsoluteImages2,
      text1: 'Front-End developer course',
      text2: 'Become a pro in front-end development. Building user interfaces using cutting-edge framework technologies like ReactJS and Angular. Convert design into code and ensure smooth experience on the client side.',
      moreDetails: FrontEnd
    },
    {
      img: AbsoluteImages3,
      text1: 'Back-End developer course',
      text2: 'Become an expert in backend development. Implementing server-side logic, manage databases, and creating APIs that support web and mobile applications using technologies like Python, Node js, Java and PHP.',
      colorMode: 'black',
      moreDetails: BackEnd,
    },
  ];
export default AbsoluteImagesData;