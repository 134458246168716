import React from 'react'
import '../Tablecol/Tablecol.css'

const Tablecol = (props) => {
    const{img1,coltitle,p1,p2,p3,p4}=props
  return (
    <div className="sublaycol">
            <img className='wedoimg' src={img1} alt='ColImg'/>
            <div className="font-face-m coltitle pt-3 pb-3">{coltitle}</div>
            <div className="font-face-r colitems">
              <p>{p1}</p> <p>{p2}</p><p>{p3}</p>{p4}
            </div>
        </div>
  )
}

export {Tablecol};