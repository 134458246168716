import React from 'react'
import logo from '../../Assets/logo.png';
import Navbar from 'react-bootstrap/Navbar';

const BrandLogo = () => {
    return (
        <Navbar.Brand href="#home">
            <img className='logo' src={logo} alt='logo' />
        </Navbar.Brand>
    )
}

export default BrandLogo