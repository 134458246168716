import React from 'react'
import '../Learn/Learn.css';
import { Helmet } from 'react-helmet';
import LearnDashimg from '../../Assets/LearnDashimg.png';
import Applicationicon from '../../Assets/Applicationicon.png';
import Todolisticon from '../../Assets/Todolisticon.png';
import Peaceofmindicon from '../../Assets/Peaceofmindicon.png';
import Webdesignicon from '../../Assets/Webdesignicon.png';
import Timelefticon from '../../Assets/Timelefticon.png';
import Moneybagicon from '../../Assets/Moneybagicon.png';
import Footer from  '../../components/Footer/Footer';
import { WhatWeDo } from '../../components/WhatWeDo/WhatWeDo';
import Absoluteimg from '../../components/AbsoluteImg/Absoluteimg';
import absoluteImagesData from '../../components/AbsoluteImg/AbsoluteImagesData';
const Learn = () => {
    const FirstrightData = [
        {
            imgSrc: Applicationicon,
            heading: 'Fundamentals',
            text: 'We believe that a lot of digital products could use some much-needed polish and attention to detail. We achieve that with our refined design process that continuously measures & evolves to meet business and user goals.',
        },
        {
            imgSrc: Todolisticon,
            heading: 'Simple day task',
            text: 'We respect your time and strive to meet realistic deadlines. We believe that when work gets done on time, you get more time to think about your vision of the future you.',
        },
        {
            imgSrc: Peaceofmindicon,
            heading: 'Soft skill to showcase',
            text: 'From our experience with 200+ clients in the last decade, we learnt that pricing is based on the value rendered to the client and not on the number of hours spent. So our pricing reflects exactly that.',
        },
    ];
    const rightData = [
        {
            imgSrc: Webdesignicon,
            heading: 'Day to Day task',
            text: 'We believe in hands-on learning and training not in daily theory lectures. Consistently exposing you to practical challenges, will engage and build your skills rapidly. We are ensuring you learn the tools and techniques used in the field.',
        },
        {
            imgSrc: Timelefticon,
            heading: 'Expert Guidance',
            text: 'Our passionate instructors who have worked in corporates successfully, will support and guide you through every concept and overcome the challenges.',
        },
        {
            imgSrc: Moneybagicon,
            heading: 'Practical-based training',
            text: 'We equip you with full practical-oriented classes, no theory-based sessions. You will be working with recent technology trends',
        },
        {
            imgSrc: Moneybagicon,
            heading: 'Productive course module',
            text: 'Based on the skills and technologies that are needed for your career, we designed a clear and concise curriculum to take you from beginner to advanced level',
        },
        {
            imgSrc: Moneybagicon,
            heading: 'Soft skill training',
            text: 'We provide essential soft skill training like interview cracking, communication skills, teamwork and presentation skills related to the fields you have chosen. This will build a strong soft skill set to enhance and add value to your career.',
        },

    ];
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learn - OnFleek Media and Technologies Pvt Ltd</title>
                <meta name="description" content="Attain advance level in software development. We offer practical software courses along with internships.Enroll now | Learn the Latest Technologies"></meta>

                {/* SEO meta tags */}
                <meta name="keywords" content="Software development training, Software training in chennai, Full stack development training in chennai, Frontend development training in chennai, Back end development training in chennai, Software course training centre, Software training centre for beginners" />
                <meta name="author" content="OnFleekMT" />

                {/* Mobile-specific meta tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="theme-color" content="#ffffff" />

                {/* Open Graph meta tags for social sharing */}
                <meta property="og:title" content="#1 Customized software development company in India | Onfleek MT" />
                <meta property="og:description" content="Attain advance level in software development. We offer practical software courses along with internships. Enroll now | Learn the Latest Technologies" />
                <meta property="og:image" content="https://www.google.com/imgres?imgurl=https%3A%2F%2Fonfleek.in%2Fwp-content%2Fuploads%2F2021%2F08%2Fon_fleek_logo-new-01-01.png&tbnid=vxFi7yc6S1iwnM&vet=12ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL..i&imgrefurl=https%3A%2F%2Fonfleek.in%2F&docid=NM98lp3yMlTm_M&w=852&h=209&q=onfleek%20media%20and%20technologies%20pvt%20ltd&ved=2ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL" />
                <meta property="og:url" content="https://onfleek.in/" />
                <meta property="og:type" content="website" />

            </Helmet>
            <section className='learnheader d-flex'>
                <img className='w-100' src={LearnDashimg} alt='LearnDashimg' />
                <div className='learncontent d-flex flex-column'>
                    <span className='over-img-text font-face-m'>Start building your skills to uplift your career</span>
                    <span className='over-img-text2 font-face-r d-block'>OnFleek learning platform is developed a significant 
                    learning process for those who want to upgrade their career in software development and digital
                     marketing from beginner level to advanced level. our experienced instructors, passionate about your growth, 
                     guide you through every step, ensuring you master in-demand skills. We always engage you with real time and
                      practical experiments</span>
                </div>
            </section>
            <WhatWeDo lefttext1="LEARNING STEPS" lefttext2="101 small steps to make you  as a professional " rightData={FirstrightData} />
            <div className='overall-layout'>
                {absoluteImagesData.map((item, index) => (
                    <Absoluteimg key={index} img1={item.img} absolutetext1={item.text1} absolutetext2={item.text2} colorMode={item.colorMode} Details={item.moreDetails} />
                ))}
            </div>
            <WhatWeDo
                lefttext1="LEARNING STEPS"
                lefttext2="101 small steps to make you as a professional "
                rightData={rightData} // Pass the rightData prop here
            />
            <Footer text="Let’s talk" subText="Look in to our works" bgColor="#FF2526" pageId='/' showForm={true} />
        </>
    )
}

export default Learn