import '../MyNavbar/MyNavbar.css';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import BrandLogo from './BrandLogo';
import { useNavigate } from 'react-router-dom';

function OffcanvasExample() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const navigate = useNavigate();

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  const handleMenuItemClick = (path) => {
    setShowOffcanvas(false);
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Container fluid className='font-face-r nav'>
        <Navbar key='md' expand='md' className="mb-3">
          <Navbar.Brand as={NavLink} to="/">
            <div className='logo position-absolute mt-0'><BrandLogo /></div>
          </Navbar.Brand>
          
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-md`}
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          
          <Navbar.Collapse>
            <Navbar.Offcanvas
              show={showOffcanvas}
              onHide={handleOffcanvasClose}
              id={`offcanvasNavbar-expand-md`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                  Onfleek
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1">
                    <NavLink to="/" className='navmenu' activeClassName='active' onClick={() => handleMenuItemClick('/')}>
                      Work
                    </NavLink>
                    <NavLink to="/about" className='navmenu' activeClassName='active' onClick={() => handleMenuItemClick('/about')}>
                      About 
                    </NavLink>
                    <NavLink to="/process" className='navmenu' activeClassName='active' onClick={() => handleMenuItemClick('/process')}>
                      Process
                    </NavLink>
                    <NavLink to="/learn" className='navmenu' activeClassName='active' onClick={() => handleMenuItemClick('/learn')}>
                      Learn
                    </NavLink>
                  </Nav>
                  <Nav>
                    <NavLink to="/contact" className='navmenu justify-content-end' activeClassName='active' onClick={() => handleMenuItemClick('/contact')}>
                      Contact Us
                    </NavLink>
                  </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
}

export default OffcanvasExample;
