import React, { memo } from 'react'
import './Process.css';
import { Helmet } from 'react-helmet';
import Horseicon from '../../Assets/Horseicon.png';
import Connectoricon from '../../Assets/Connectoricon.png';
import Datamanagementicon from '../../Assets/Datamanagementicon.png';
import Researchicon from '../../Assets/Researchicon.png';
import Webdesignicon from '../../Assets/Webdesignicon.png';
import Timelefticon from '../../Assets/Timelefticon.png';
import Moneybagicon from '../../Assets/Moneybagicon.png';
import Footer from '../../components/Footer/Footer';
import { WhatWeDo } from '../../components/WhatWeDo/WhatWeDo';
import { Outset } from '../../components/Outset/Outset';
import { Bodylayout } from '../../components/Bodylayout/Bodylayout';
import { Tablecol } from '../../components/Tablecol/Tablecol';
import TablecolData from '../../components/Tablecol/Table';

const Process = memo(() => {
  const bodycolItems = [{ img1: Researchicon, title: 'Research' }, { img1: Horseicon, title: 'Strategy' }, { img1: Connectoricon, title: 'Design' }, { img1: Datamanagementicon, title: 'Execute' },];
  const rightData = [
    {
      imgSrc: Webdesignicon,
      heading: 'Premium Design',
      text: 'We believe that a lot of digital products could use some much-needed polish and attention to detail. We achieve that with our refined design process that continuously measures & evolves to meet business and user goals.',
    },
    {
      imgSrc: Timelefticon,
      heading: 'Always on Time',
      text: 'We respect your time and strive to meet realistic deadlines. We believe that when work gets done on time, you get more time to think about your vision of the future you.',
    },
    {
      imgSrc: Moneybagicon,
      heading: 'Affordable Pricing',
      text: 'From our experience with 200+ clients in the last decade, we learnt that pricing is based on the value rendered to the client and not on the number of hours spent. So our pricing reflects exactly that.',
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Process - OnFleek Media and Technologies Pvt Ltd</title>
        <meta name="description" content="Aim to provide premium software products which are Innovative and transformative solutions to businesses. Click here to ignite the digital tranform."></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="Premium software development, premium software service, Web application developer, Software service provider, IT service provider, IT service provider company" />
        <meta name="author" content="OnFleekMT" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="Process - OnFleek Media and Technologies Pvt Ltd" />
        <meta property="og:description" content="Aim to provide premium software products which are Innovative and transformative solutions to businesses. Click here to ignite the digital tranform" />
        <meta property="og:image" content="https://www.google.com/imgres?imgurl=https%3A%2F%2Fonfleek.in%2Fwp-content%2Fuploads%2F2021%2F08%2Fon_fleek_logo-new-01-01.png&tbnid=vxFi7yc6S1iwnM&vet=12ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL..i&imgrefurl=https%3A%2F%2Fonfleek.in%2F&docid=NM98lp3yMlTm_M&w=852&h=209&q=onfleek%20media%20and%20technologies%20pvt%20ltd&ved=2ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL" />
        <meta property="og:url" content="https://onfleek.in/" />
        <meta property="og:type" content="website" />

      </Helmet>
      <section id='process'>
        <Outset lefttext="Why user centric design and development?" p1="By placing users at the center of software design and development, we dig deep into user’s  behaviors, Preferences and Challenges to develop the product. We ensure that we craft  products that are not just usable, but truly delightful. " p2="As we study their behaviors and preferences in prior, the outcome product will attract the users and provide leads to the business." p3="This results in better usability, responsive and overall success of the product." />
      </section>
      <section>
        <Bodylayout text1="OUR PROCESS" text2="We are involved in the complete process from A to Z in developing the  product to deliver a delightful user experience." />
        <div className='bodycol justify-content-center d-flex'>
          {bodycolItems.map((item, index) => (
            <div className='bodycol-items' key={index}>
              <img className='wedoimg' src={item.img1} alt={`Image1${index + 1}`} />
              <span className='itemstitle font-face-m' style={{ fontSize: '24px' }}>{item.title}</span>
            </div>
          ))}
        </div>
      </section>
      <WhatWeDo lefttext1="WHY OnFleek" lefttext2="Always on quality, timeline and budget check." rightData={rightData} />
      <section>
        <Bodylayout text1="WHAT WE DO" text2="We are committed to creating digital solutions for businesses." />
        <div className='d-flex justify-content-center'>
          <div className='sublay d-flex'>
            {TablecolData.map((item, index) => (
              <Tablecol key={index} img1={item.img1} coltitle={item.coltitle} p1={item.p1} p2={item.p2} p3={item.p3} p4={item.p4} />
            ))}
          </div>
        </div>
      </section>
      <Footer text="101 small steps to make you  as a professional " subText="Move on to our training process" bgColor="#1F87FC" pageId='learn' />
    </>
  )
})

export default Process