import React from 'react'
import '../Bodylayout/Bodylayout.css'
const Bodylayout =(props) => {
    const{text1,text2}=props
  return (
    <div className='bodytext-layout justify-content-center'>
        <div className='text1 font-face-m mt-5 mb-3'>{text1}</div>
        <div className='text2 font-face-r w-100'>{text2}</div>
    </div>
  )
}

export {Bodylayout};