import './App.css';
import { Home } from "../src/Pages/Home/Home";
import { About } from "../src/Pages/About/About";
import NavbarMenu from './components/MyNavbar/MyNavbar';
import { Routes, Route } from 'react-router-dom';
import Process from './Pages/Process/Process';
import Learn from './Pages/Learn/Learn';
import Contact from './Pages/Contact/contact';
function App() {
  return (
    <div className="App">
        <NavbarMenu />
          <Routes>    
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/process" element={<Process/>} />
            <Route path="learn" element={<Learn/>} />
            <Route path="contact" element={<Contact/>}/>
          </Routes>
    </div>
  );
}

export default App;
