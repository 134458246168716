import React from 'react'
import { Button } from 'react-bootstrap';
import '../AbsoluteImg/Absoluteimg.css';
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
const Absoluteimg =(props) => {
    const{img1,absolutetext1,absolutetext2, colorMode, Details}=props;
    const openLink = () => {
      const pdfUrl = Details;
      window.open(pdfUrl, '_blank');
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Course_Details.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  return (
    <div className={`learn-layout position-relative `}>
                <img className='over-img' src={img1} alt='Image12'/>
                <div className='flow-layout'>
                    <span className={`learn-layout-text1 font-face-r d-block  ${colorMode==='black' ? 'text-dark' : ''}`}>{absolutetext1}</span>
                    <span className={`learn-layout-text2 font-face-l d-block ${colorMode==='black' ? 'text-dark' : ''}`}>{absolutetext2}</span>
                    <Button className='learn-layout-button d-block' variant="outline-danger" onClick={openLink}>Know more</Button>
                </div>
            </div>
  )
}

export default Absoluteimg;