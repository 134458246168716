import React from 'react'
import '../Outset/Outset.css'
const Outset =(props) => {
    const{ lefttext,p1,p2,p3,h1,h2,h3 }=props;
  return (
    <div className='row abouthome d-flex justify-content-center w-100'>
        <div className='col custom-padding-left-inherit'>
            <div className='leftlayout'>
                <span className='lefttext font-face-r'>{lefttext}</span>
            </div>
        </div>
        <div className='col righttext font-face-r'>
            <h3 className='text-dark font-face-m' >{h1}</h3>
            <p >{p1}</p>
            <h3 className='text-dark font-face-m'>{h2}</h3>
            <p >{p2}</p>
            <h3 className='text-dark font-face-m'>{h3}</h3>
            <p >{p3}</p>
        </div>
    </div>
  )
}

export {Outset};