import React, { memo, useState } from 'react';
import emailjs from 'emailjs-com';
import '../Footer/Form.css';

const Form = memo(() => {
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [lookingFor, setLookingFor] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validation, setValidation] = useState({
    name: true,
    mobileNumber: true,
    lookingFor: true,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Disable submit button and show loader
    setIsSubmitting(true);

    // Perform validation
    const isNameValid = /^[a-zA-Z]+$/.test(name.trim());
    const isMobileNumberValid = /^\d{10}$/.test(mobileNumber.trim());
    const isLookingForValid = /^[a-zA-Z\s]{1,100}$/.test(lookingFor.trim());

    // Update validation state
    setValidation({
      name: isNameValid,
      mobileNumber: isMobileNumberValid,
      lookingFor: isLookingForValid,
    });

    // Check if the form is valid before submission
    if (isNameValid && isMobileNumberValid && isLookingForValid) {
      // Construct the HTML for the email content
      const emailContent = `
        <table>
          <tr>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>Looking For</th>
          </tr>
          <tr>
            <td>${name}</td>
            <td>${mobileNumber}</td>
            <td>${lookingFor}</td>
          </tr>
        </table>
      `;

      // Use Email.js to send the email
      emailjs
        .send('service_Onfleek', 'template_Choice', {
          message_html: emailContent,
        }, 'a1ttOuxmkueXP9KhD')
        .then((response) => {
          alert('Email sent successfully');

          // Reset the form
          setName('');
          setMobileNumber('');
          setLookingFor('');
          setValidation({
            name: true,
            mobileNumber: true,
            lookingFor: true,
          });

          // Enable submit button and hide loader
          setIsSubmitting(false);
        })
        .catch((error) => {
          alert.error('Error sending email:', error);

          // Enable submit button and hide loader in case of an error
          setIsSubmitting(false);
        });
    } else {
      // Enable submit button and hide loader if validation fails
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='center d-inline-flex font-face-l md-d-flow sm-d-flow'>
        <div className={`inputbox ${!validation.name ? 'invalid' : ''}`}>
          <input
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span>Enter Your Full Name *</span>
          {!validation.name && <div className="error-message">Name should only contain letters. *</div>}
        </div>

        <div className={`inputbox d-block ${!validation.mobileNumber ? 'invalid' : ''}`}>
          <input
            type="text"
            required
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          <span>Enter Your Mobile Number *</span>
          {!validation.mobileNumber && <div className="error-message">Mobile number should be 10 digits. *</div>}
        </div>

        <div className={`inputbox ${!validation.lookingFor ? 'invalid' : ''}`}>
          <input
            type="text"
            required
            value={lookingFor}
            onChange={(e) => setLookingFor(e.target.value)}
          />
          <span>What are you looking for? *</span>
          {!validation.lookingFor && <div className="error-message">Looking for should contain only letters and spaces (max 100 characters). *</div>}
        </div>
      </div>

      <div className="inputboxsubmit font-face-l">
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Submit'}
        </button>
        {isSubmitting && <div className="loader"></div>}
      </div>
    </form>
  );
});

export default Form;