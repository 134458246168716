import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import contact from '../../Assets/Contact.png';
import Footer from '../../components/Footer/Footer';
import '../Contact/contact.css';

const Contact = memo(() => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - OnFleek Media and Technologies Pvt Ltd</title>
        <meta name="description" content="We derive innovative software web or applications that stand as a solution to business challenges. Develop your ideas into a software solution - Know More"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="software development company in india, software development company in chennai, Application development, application and software devleoper, Web development company, Full stack development service, Front end development service, Back end development service, Software company, IT company" />
        <meta name="author" content="OnFleekMT" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="About Us - OnFleek Media and Technologies Pvt Ltd" />
        <meta property="og:description" content="We derive innovative software web or applications that stand as a solution to business challenges. Develop your ideas into a software solution - Know More" />
        <meta property="og:image" content="https://www.google.com/imgres?imgurl=https%3A%2F%2Fonfleek.in%2Fwp-content%2Fuploads%2F2021%2F08%2Fon_fleek_logo-new-01-01.png&tbnid=vxFi7yc6S1iwnM&vet=12ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL..i&imgrefurl=https%3A%2F%2Fonfleek.in%2F&docid=NM98lp3yMlTm_M&w=852&h=209&q=onfleek%20media%20and%20technologies%20pvt%20ltd&ved=2ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL" />
        <meta property="og:url" content="https://onfleek.in/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className='learnheader d-flex position-relative'>
        <img className='w-100 h-100 img-fluid' src={contact} alt='Image1' />
        <div className='Contactdetails w-100'>
          <span className='over-img-contacttext font-face-m'>Contact Us</span>
          <div className='regadd'>
            <span className='over-img-addresstex1 font-face-m '>Reg Address</span>
            <span className='over-img-contacttext2 font-face-r '>No. 236, Lakshmi Avenue, IAF Road,<br /> Selaiyur, Chennai - 600073</span>
          </div>
          <div className='regadd'>
            <span className='over-img-addresstex1 font-face-m'>Office Address</span>
            <span className='over-img-contacttext2 font-face-r'>Plot.no: 66A, BHEL Nagar, 1st main road,<br/> Medavakkam koot road, Chennai - 600 100<hr style={{ borderColor: 'transparent' }} /><p> hr@onfleek.in <br />+91 99411 11019</p></span>
          </div>
        </div>
      </section>
      <Footer text="Let’s build something for the great" subText="Send us a mail" bgColor="#491C96" pageId="contact" />
    </>
  )
})

export default Contact