import Horseicon from '../../Assets/Horseicon.png';
import Connectoricon from '../../Assets/Connectoricon.png';
import Datamanagementicon from '../../Assets/Datamanagementicon.png';
const TablecolData = [
    {
      img1: Horseicon,
      coltitle: 'Strategy',
      p1:'UI Research / Analysis',
      p2:'UX Consultancy',
      p3:'Information Architecture',
      p4:'User Testing',
    },
    {
      img1: Connectoricon,
      coltitle: 'Design',
      p1:'User Experience',
      p2:'Interface Design',
      p3:'Illustration / Animation',
      p4:'Brand Development',
    },
    {
      img1: Datamanagementicon,
      coltitle: 'Technology',
      p1:'HTML / CSS / Javascript',
      p2:'React / Vue',
      p3:'PHP / NodeJS',
      p4:'DevOps',
    },
  ];
export default TablecolData;