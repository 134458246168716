import React from 'react'
import '../Home/Home.css';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Dashboard from '../../Assets/Dashboard.jpg';
import MechToolz from '../../Assets/MechToolz.png';
import Dashboard2 from '../../Assets/DashboardImage2.png';
import Footer from '../../components/Footer/Footer';
const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - OnFleek Media and Technologies Pvt Ltd</title>
        <meta name="description" content="OnFleek is a leading custom software development company, that provides excellent software services and digital solutions to customers. Connect us - know more"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="software product development company, Software product development, Mobile application development, Custom software development company, software business" />
        <meta name="author" content="OnFleekMT" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="#1 Customized software development company in India | Onfleek MT" />
        <meta property="og:description" content="OnFleek is a leading custom software development company, that provides excellent software services and digital solutions to customers. Connect us - know more" />
        <meta property="og:image" content="https://www.google.com/imgres?imgurl=https%3A%2F%2Fonfleek.in%2Fwp-content%2Fuploads%2F2021%2F08%2Fon_fleek_logo-new-01-01.png&tbnid=vxFi7yc6S1iwnM&vet=12ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL..i&imgrefurl=https%3A%2F%2Fonfleek.in%2F&docid=NM98lp3yMlTm_M&w=852&h=209&q=onfleek%20media%20and%20technologies%20pvt%20ltd&ved=2ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL" />
        <meta property="og:url" content="https://onfleek.in/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className='layout-white w-100 text-center'>
        <span className='hometext font-face-r'>We carve and mold your ideas <br /> into insightful software products</span>
      </div>
      <div className='layout-gray d-flex justify-content-center position-relative'>
        <div className="layout-image position-absolute">
          <img className='mobile-ads w-100 h-100' src={Dashboard} alt='Image1' />
        </div>
      </div>
      <Container className='imgs'>
        <div className='image-layout d-flex justify-content-center'>
          <img className='mobile-ads w-100' src={MechToolz} alt='Mechtoolz.com' />
        </div>
        <div className='image-layout d-flex justify-content-center'>
          <img className='mobile-ads w-100 h-100' src={Dashboard2} alt='Image3' />
        </div>
      </Container>
      <Footer text="Start building your audience with your software products" subText="Get to know more about us" bgColor="#00AB47" pageId='about' />

    </>
  )
};
export { Home };
