import React, { memo } from 'react'
import '../About/About.css';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import { Outset } from '../../components/Outset/Outset';
import { Bodylayout } from '../../components/Bodylayout/Bodylayout';
import { Tablecol } from '../../components/Tablecol/Tablecol';
import TablecolData from '../../components/Tablecol/Table';
const About = memo(() => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - OnFleek Media and Technologies Pvt Ltd</title>
        <meta name="description" content="We derive innovative software web or applications that stand as a solution to business challenges. Develop your ideas into a software solution - Know More"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="software development company in india, software development company in chennai, Application development, application and software devleoper, Web development company, Full stack development service, Front end development service, Back end development service, Software company, IT company" />
        <meta name="author" content="OnFleekMT" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="About Us - OnFleek Media and Technologies Pvt Ltd" />
        <meta property="og:description" content="We derive innovative software web or applications that stand as a solution to business challenges. Develop your ideas into a software solution - Know More" />
        <meta property="og:image" content="https://www.google.com/imgres?imgurl=https%3A%2F%2Fonfleek.in%2Fwp-content%2Fuploads%2F2021%2F08%2Fon_fleek_logo-new-01-01.png&tbnid=vxFi7yc6S1iwnM&vet=12ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL..i&imgrefurl=https%3A%2F%2Fonfleek.in%2F&docid=NM98lp3yMlTm_M&w=852&h=209&q=onfleek%20media%20and%20technologies%20pvt%20ltd&ved=2ahUKEwj9t_2ftsODAxWdTWwGHXWkCfgQMygCegQIARBL" />
        <meta property="og:url" content="https://onfleek.in/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section id='about'>
        <Outset lefttext="We build world-class software products that solve real problems" h1="What?" h2="How?" h3="Who?" p1="At onfleek, we aim to create user-centric design for business challenges as a solution. Our primary focus is developing customized high-performing products that drive results faster to business challenges." p2=" We don't just design things and develop, we design and develop solutions by understanding your business objectives and strategy, translating them into responsive and tangible results." p3="As a Solid team of experienced engineers and seasoned developers, we use the latest technology trends to place a high value on ongoing communication and prioritize dynamic prototyping over static screens." />
      </section>
      <section>
        <Bodylayout text1="WHAT WE DO" text2="We are committed to creating digital solutions for businesses" />
        <div className='d-flex justify-content-center'>
          <div className='sublay d-flex'>
            {TablecolData.map((item, index) => (
              <Tablecol key={index} img1={item.img1} coltitle={item.coltitle} p1={item.p1} p2={item.p2} p3={item.p3} p4={item.p4} />
            ))}
          </div>
        </div>
      </section>
      <Footer text="Always on quality, timeline and budget check." subText="Have a look of our process" bgColor="#D8A214" pageId='process' />
    </>
  )
})

export { About };